import React from "react";
import {graphql} from "gatsby";
import CategoryLayout from "../../components/tronpedia/category-layout";
import {Helmet} from "react-helmet";
import blockchainBull from "../../images/tronpedia/topic-pages/blockchain-bull.png";

const Search = ({data, location}) => {

    let searchPhrase = new URLSearchParams(location.search).get("q");
    if (searchPhrase === null) {
        searchPhrase = ""
    }


    const pageData = {
        title: "Search: \"" + searchPhrase + "\"",
        description: "TRONPEDIA includes articles that provide a foundational understanding for multiple components of cyber security.",
        img: blockchainBull,
    }

    let results = [];
    for (let i = 0; i < data.allWpTronpediaArticle.edges.length; i++) {
        let edge = data.allWpTronpediaArticle.edges[i];
        if (searchPhrase !== null & edge.node.tronpedia_article_info.article !== null) {
            if (edge.node.tronpedia_article_info.article.toLowerCase().includes(searchPhrase.toLowerCase())) {
                results.push(edge);
            }
        }
    }

    let queryData = {
        "allWpTronpediaArticle": {
            "edges": results
        }
    }


    return (
        <>
            <Helmet>
                <title>TRONpedia Search | TRONDAO</title>
                <meta name="description"
                      content="TRONPedia is the most comprehensive directory of web3 related content on the internet. Visit us today to find exactly what you are looking for!"/>
                <meta property="og:description"
                      content="TRONPedia is the most comprehensive directory of web3 related content on the internet. Visit us today to find exactly what you are looking for!"/>
                <meta property="og:title" content="TRONpedia Search | TRONDAO"/>
            </Helmet>
            <CategoryLayout data={queryData} location={location} pageData={pageData}/>
        </>
    );
};


export default Search;

export const pageQuery = graphql`
query {
   allWpTronpediaArticle  {  
      edges {
         node {
            slug
        title
        tronpedia_article_info {
          article
          category
          featured
          seoDescription
          seoTitle
          shortDescription
          image {
                  sourceUrl
               }
            }
         }
      }
   }
}

`